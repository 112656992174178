@import "../variables";

// Colors
$white: #fff !default;
$black: #000 !default;
$red: #c23934 !default;
$dark-blue: #061c3f;

$gray-1: #222 !default;
$gray-2: #444 !default;
$gray-3: #999 !default;
$gray-4: #ccc !default;

$gray-transparent-1: rgba(0, 0, 0, 0.4) !default;

$light-gray: $gray-4 !default;
$dark-gray: $gray-1 !default;

// Font Colors
$white-text: $white !default;

// Font sizes
$base-font-size: 1rem !default;
$xl-font-size: $base-font-size * 1.67 !default;
$lg-font-size: $base-font-size * 1.5 !default;

$heading-1-font-size: $base-font-size * 4 !default;
$heading-2-font-size: $base-font-size * 3 !default;
$heading-3-font-size: $xl-font-size !default;
$heading-4-font-size: $lg-font-size !default;

// Font family
$base-font-family: "Helvetica Neue" !default;

// Font weight
$base-font-weight: 400 !default;
$font-weight-huskie: 600 !default;
$font-weight-heavy: 700 !default;
$font-weight-heavier: 900 !default;

// Thin Banner
$thin-banner-font: $base-font-family !default;
$thin-banner-background-color: $dark-blue !default;
$thin-banner-text-color: $white !default;
$thin-banner-link-color: $white !default;
$thin-banner-visited-link-color: $white !default;

// Main Banner
$banner-font: $base-font-family !default;
$banner-heading-text-color: $white !default;
$banner-sub-text-color: $white !default;
$banner-sub-text-font-size: $xl-font-size !default;

// Image Text Component
$image-text-font: $base-font-family !default;

// Link Banner Component
$shop-category-font: $base-font-family !default;
$shop-category-font-size: $base-font-size !default;
$shop-category-header-color: $gray-1 !default;
$shop-category-header-font-color-other: $white !default;
$shop-category-header-font-weight: $font-weight-heavier !default;
$shop-category-label-a-color: $gray-1 !default;
$shop-category-label-a-color-other: $white !default;

// Product
$product-font: $base-font-family !default;
$product-name-color: $gray-1 !default;
$product-ratings-number-color: $gray-2 !default;
$product-ratings-weight: $base-font-weight !default;
$product-quick-shop-box-shadow-color: $gray-transparent-1 !default;
$product-swatch-shadow-color: $gray-4 !default;
$product-swatch-shadow-active-color: $gray-3 !default;

// Product Tile
$product-tile-more-swatch-color: $gray-2 !default;
$product-tile-quick-shop-background-color: $white !default;
$product-tile-quick-shop-text-color: $black !default;
$product-tile-quick-shop-font-weight: $font-weight-huskie !default;

// Price
$price-color: $gray-1 !default;
$price-font-weight: $font-weight-heavy !default;
$strike-through-price-color: $gray-3 !default;
$reduced-price-color: $red !default;

// Shop the Look Component
$shop-the-look-text-color: $white !default;
$shop-the-look-link-color: $black !default;
$shop-the-look-product-name-text-size: $heading-3-font-size !default;
$shop-the-look-product-name-text-weight: $font-weight-heavier !default;
